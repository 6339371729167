import axios from "axios";

axios.defaults.baseURL = 'https://api.yanchuang.ycsd.work/api/site/'

const get = function (uri, data, callback) {

    if (!callback) {
        callback = data
        data = {}
    }

    axios.get(uri, {
        params:data
    }).then((res) => {
        callback(res.data, res.status === 200)
    })
}

const post = function (uri, data, callback) {
    if (!callback) {
        callback = data
        data = {}
    }

    axios.post(uri, {
        params:data
    }).then((res) => {
        callback(res.data, res.status === 200)
    })
}

export default {
    get,
    post
}
